@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: white;
}

#kc-info {
    padding-bottom: 40px;
    margin-bottom: 100px;
}

.dialog-shadow {
    box-shadow: rgb(136 136 136) 0px 0px 6px;
}